@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");@tailwind base;@tailwind components;@tailwind utilities;@font-face{font-family:"Satoshi";src:url("../fonts/Satoshi-Regular.woff2") format("woff2"),url("../fonts/Satoshi-Regular.woff") format("woff"),url("../fonts/Satoshi-Medium.woff") format("woff"),url("../fonts/Satoshi-Medium.woff2") format("woff2"),url("../fonts/Satoshi-Regular.ttf") format("truetype"),url("../fonts/Satoshi-Light.woff") format("truetype");font-weight:400;font-display:swap;font-style:normal}
p div{@apply text-inherit}@layer utilities{.container{@apply mx-auto max-lg:px-5 lg:px-[30px] xl:p-0 xl:max-w-[1130px]}
.container-fluid{@apply w-full px-4 xl:mx-auto}img{@apply max-w-full h-auto align-middle bg-no-repeat bg-cover;shape-margin:1rem}
.rv-badge{@apply inline-block border border-colorText dark:border-colorText rounded-[72px] bg-backgroundBody/20 dark:bg-secondary}
.rv-badge-text{@apply text-black dark:text-[#FFF] text-xs uppercase tracking-[0.96px] py-2 px-4 bg-gradient-to-r from-secondary to-gray-400 dark:bg-gradient-to-r dark:from-white dark:to-[#bdbdbd] text-transparent dark:text-transparent bg-clip-text dark:bg-clip-text font-semibold inline-block}
}@layer base{*{@apply text-black dark:text-white}h1,h2,h3,h4,h5,h6{@apply font-satoshi text-black dark:text-white}
h1{@apply text-5xl sm:text-[55px] md:text-[65px] lg:text-[80px] xl:text-8xl font-normal leading-tight xl:leading-[1.18] tracking-[-2px] xl:tracking-[-2.88px]}
h2{@apply text-[38px] md:text-[47px] lg:text-[54px] xl:text-[64px] font-normal leading-[1.3] md:leading-[1.2] lg:leading-[1.24]}
h3{@apply text-[27px] lg:text-[42px] xl:text-[50px] font-normal leading-tight lg:leading-[1.2] lg:tracking-[-1.68px]}
h4{@apply text-[25px] lg:text-5xl lg:leading-[1.2] font-normal}h5{@apply text-[22px] lg:text-4xl lg:leading-[1.2] lg:-tracking-[1.08px] font-normal}
h6{@apply text-xl lg:text-[32px] lg:leading-[1.05] font-normal}p{@apply text-base md:text-lg lg:text-xl font-normal lg:leading-[1.4] text-colorText dark:text-dark-100}
em{@apply not-italic}a{@apply inline-block text-sm font-medium leading-[1.1] text-black dark:text-white}
}.tab-button{@apply relative transition-all duration-[0.6s]}.tab-button.active{@apply bg-primary}
.tab-content{animation:fadeIn .4s ease-in-out}@keyframes fadeIn{from{opacity:0;transform:translateY(15px)}
to{opacity:1;transform:translateY(0)}}.rv-button{@apply overflow-hidden relative}
.rv-button .rv-button-top,.rv-button .rv-button-bottom{@apply py-[25px] px-[39px] block w-full md:inline-block md:w-auto transition duration-500 min-w-[210px]}
.rv-button .rv-button-top span,.rv-button .rv-button-bottom span{@apply inline-block transition duration-500 font-semibold uppercase tracking-[1.12px]}
.rv-button .rv-button-top{@apply relative z-10}.rv-button .rv-button-bottom{@apply absolute w-full h-full top-0 left-0 translate-y-full z-[9]}
.rv-button .rv-button-bottom span{@apply rotate-12}.rv-button:hover .rv-button-top{@apply -translate-y-[101%]}
.rv-button:hover .rv-button-top span{@apply -rotate-12}.rv-button:hover .rv-button-bottom{@apply translate-y-0}
.rv-button:hover .rv-button-bottom span{@apply rotate-0}.rv-button-secondary .rv-button-top{@apply bg-secondary dark:bg-backgroundBody border border-secondary dark:border-backgroundBody z-10}
.rv-button-secondary .rv-button-top span{@apply text-backgroundBody dark:text-secondary}
.rv-button-secondary .rv-button-bottom{@apply bg-backgroundBody dark:bg-secondary border border-secondary dark:border-colorText z-[9]}
.rv-button-secondary .rv-button-bottom span{@apply text-secondary dark:text-backgroundBody}
.rv-button-white .rv-button-top{@apply bg-backgroundBody dark:bg-dark border dark:border-dark z-10}
.rv-button-white .rv-button-top span{@apply text-secondary dark:text-backgroundBody}
.rv-button-white .rv-button-bottom{@apply bg-secondary dark:bg-backgroundBody border border-secondary dark:border-backgroundBody z-[9]}
.rv-button-white .rv-button-bottom span{@apply text-backgroundBody dark:text-secondary}
.rv-button-primary .rv-button-top{@apply bg-primary border z-10}.rv-button-primary .rv-button-top span{@apply text-secondary}
.rv-button-primary .rv-button-bottom{@apply bg-secondary border border-secondary z-[9]}
.rv-button-primary .rv-button-bottom span{@apply text-backgroundBody}.rv-button-primary2 .rv-button-top{@apply bg-white dark:bg-inherit border dark:border-dark z-10}
.rv-button-primary2 .rv-button-top span{@apply text-secondary dark:text-backgroundBody}
.rv-button-primary2 .rv-button-bottom{@apply bg-primary border border-primary z-[9]}
.rv-button-primary2 .rv-button-bottom span{@apply text-secondary}.rv-button.rv-button-sm .rv-button-top,.rv-button.rv-button-sm .rv-button-bottom{@apply py-4 md:py-[19px] px-5 md:px-[30px] min-w-[127px] md:min-w-[150px]}
.horizontal-marquee{display:flex;flex-wrap:nowrap;overflow:hidden;gap:var(--_gap);max-width:100%}
.horizontal-marquee.paused .horizontal-marquee-inner{animation-play-state:paused}
.horizontal-marquee.full .horizontal-marquee-inner{min-width:var(--_containerWidth)}
.horizontal-marquee-inner{display:flex;align-items:center;justify-content:space-around;flex-wrap:nowrap;gap:var(--_gap);white-space:nowrap}
[data-animate=true] .horizontal-marquee-inner{animation:horizontal-marquee var(--_speed) linear infinite var(--_direction)}
@keyframes horizontal-marquee{0%{transform:translate(calc(var(--_gap) * -1))}
100%{transform:translate(calc(-100% - var(--_gap) * 2))}}.vertical-marquee{display:flex;flex-wrap:nowrap;overflow:hidden;margin-bottom:var(--_gap);max-height:100%;flex-direction:column;height:calc((var(--_containerSize) - var(--_vGap)) / 1.2)}
.vertical-marquee.smooth{-webkit-mask:linear-gradient(180deg,transparent,#fff 20%,#fff 80%,transparent);mask:linear-gradient(180deg,transparent,#fff 20%,#fff 80%,transparent)}
.vertical-marquee.paused .vertical-marquee-inner{animation-play-state:paused}.vertical-marquee-inner{flex-shrink:0;display:flex;align-items:center;justify-content:space-around;flex-wrap:nowrap;margin-bottom:var(--_vGap)}
[data-animate=true] .vertical-marquee-inner{animation:vertical-marquee var(--_speed) linear infinite var(--_direction)}
.vertical-marquee-inner>:not(:last-child){margin-right:var(--_hGap)}@keyframes vertical-marquee{0%{transform:translateY(calc(var(--_vGap) * -1))}
100%{transform:translateY(calc(-100% - var(--_vGap) * 2))}}body{@apply max-w-[100vw] overflow-x-hidden m-0 p-0 antialiased font-satoshi bg-black}
*::after,*::before{@apply leading-none text-[0]}.fa-solid::after,.fa-solid::before,.fa-brands::after,.fa-brands::before,.fa-regular::after,.fa-regular::before{@apply leading-normal text-base}
html.lenis{@apply h-auto}.lenis.lenis-smooth{@apply scroll-auto}.lenis.lenis-smooth[data-lenis-prevent]{@apply overscroll-contain}
.lenis.lenis-stopped{@apply overflow-hidden}.lenis.lenis-scrolling iframe{@apply pointer-events-none}
@keyframes textRotation{from{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes marqueeAni{0{@apply translate-x-0}
100%{@apply -translate-x-full}}@keyframes slide-up{from{@apply opacity-0 duration-150}
to{@apply opacity-100 duration-150}}.text{@apply absolute w-full h-full font-satoshi uppercase;animation:textRotation 20s linear infinite}
.text span{@apply absolute left-1/2 top-[8%] text-xs font-satoshi font-semibold text-white dark:text-secondary;transform-origin:0 70px;will-change:transform}
.text-appear .line,.text-appear-2 .line{@apply overflow-hidden text-inherit}.text-appear .line span,.text-appear-2 .line span{@apply text-inherit}
.text-appear .line .word,.text-appear-2 .line .word{@apply text-inherit}canvas{@apply relative -top-[1px] -left-[1px] -right-[1px]}
.accordion-header-icon{@apply absolute w-[22px] md:w-10 h-[22px] md:h-10 rounded-full border border-[#e5e5e5] right-3 md:right-10 top-6 md:top-[29px] duration-[0.4s] ease-faq-body-transition}
.accordion-header-icon::before,.accordion-header-icon::after{@apply absolute content-[""] w-[1px] h-3 md:h-5 bg-secondary dark:bg-backgroundBody top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-[0.4s] ease-faq-body-transition}
.accordion-header-icon::after{@apply -translate-x-1/2 -translate-y-1/2 rotate-90}
.accordion-header-iconV3{@apply bg-primary inline-flex size-14 max-lg:px-4 md:size-24 relative duration-[0.6s] ease-faq-body-transition transition-all}
.accordion-header-iconV3 svg{@apply rotate-0 ease-faq-body-transition duration-700 transition-all}
.accordion-header-iconV4{@apply absolute w-[24.5px] md:w-10 h-[24.5px] md:h-10 rounded-full border border-[#e5e5e5] right-7 bottom-7 duration-[0.4s] ease-faq-body-transition}
.accordion-header-iconV4::before,.accordion-header-iconV4::after{@apply absolute content-[""] w-[1px] h-3.5 md:h-5 bg-secondary dark:bg-backgroundBody top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-[0.4s] ease-faq-body-transition}
.accordion-header-iconV4::after{@apply -translate-x-1/2 -translate-y-1/2 rotate-90}
.accordion-header-iconV5{@apply bg-white dark:bg-dark border dark:border-dark inline-flex size-12 max-lg:px-4 md:size-24 relative duration-[0.4s] ease-faq-body-transition transition-all}
.accordion-header-iconV5 svg{@apply rotate-0 ease-faq-body-transition duration-300 transition-all}
.accordion-header-iconV5 svg path{@apply dark:stroke-white}.active{@apply bg-inherit}
.active .accordion-header-icon{@apply bg-secondary dark:bg-backgroundBody border border-secondary dark:border-backgroundBody}
.active .accordion-header-icon::before,.active .accordion-header-icon::after{@apply bg-backgroundBody dark:bg-secondary}
.active .accordion-header-icon::before{@apply -translate-x-1/2 -translate-y-1/2 rotate-[270deg]}
.active .accordion-header-iconV3{@apply bg-black duration-[0.4s] ease-faq-body-transition transition-all}
.active .accordion-header-iconV3 svg{@apply rotate-90 ease-faq-body-transition duration-300}
.active .accordion-header-iconV3 svg path{@apply stroke-white transition-all duration-150}
.active .accordion-header-iconV4{@apply bg-secondary dark:bg-primary border border-secondary dark:border-primary}
.active .accordion-header-iconV4::before,.active .accordion-header-iconV4::after{@apply bg-backgroundBody dark:bg-secondary}
.active .accordion-header-iconV4::before{@apply -translate-x-1/2 -translate-y-1/2 rotate-[270deg]}
.active .accordion-header-iconV5{@apply bg-black dark:bg-primary duration-[0.4s] ease-faq-body-transition transition-all}
.active .accordion-header-iconV5 svg{@apply rotate-90 ease-faq-body-transition duration-300}
.active .accordion-header-iconV5 svg path{@apply stroke-white dark:stroke-black transition-all duration-150}
.our-team-details{@apply relative transition-opacity duration-300 ease-team-bezier}
.our-team-details figure img,.our-team-details h2,.our-team-details p{@apply transition-all duration-300 ease-team-bezier}
.our-team-details.transitioning{@apply opacity-0 translate-y-2.5 ease-team-bezier}
.our-team-details.transitioning figure img,.our-team-details.transitioning h2,.our-team-details.transitioning p{@apply opacity-0 translate-y-2.5 ease-team-bezier}
.tab-member{@apply relative overflow-hidden cursor-pointer transition-all duration-300 ease-team-bezier translate-y-0}
.tab-member::after{@apply absolute content-[""] bottom-0 left-0 w-0 h-[1px] bg-secondary dark:bg-primary transition-all duration-500 ease-team-bezier}
.tab-member:hover::after{@apply w-full}.tab-member:hover:not(.tab-active){@apply -translate-y-[2.5px]}
.tab-member.tab-active{@apply bg-secondary dark:bg-primary text-backgroundBody -translate-y-[2px] transition-all duration-300 ease-team-bezier}
.tab-member.tab-active h3{@apply text-white dark:text-secondary transition-colors duration-300 ease-team-bezier}
.tab-member.tab-active p{@apply dark:text-colorText transition-colors duration-300 ease-team-bezier}
.project-details-body img:not(:first-child){@apply my-9 md:my-[60px]}.project-details-body h3{@apply mt-9 md:mt-[60px] text-[27px] lg:text-[38px] xl:text-[48px] font-normal leading-tight lg:leading-[1.2] lg:tracking-[-1.68px] mb-5}
.project-details2-body h3{@apply mt-9 md:mt-[60px] text-[27px] lg:text-[38px] xl:text-[48px] font-normal leading-tight lg:leading-[1.2] lg:tracking-[-1.68px] mb-5}
.project-details2-body p{@apply [&>*:not(:first-child)]:mt-5 max-w-[970px] md:text-[22px] md:leading-[1.4] text-colorText dark:text-dark-100}
.project-details2-body img{@apply mt-9 md:mt-20}.blog-details-body{@apply lg:-mt-[20px]}
.blog-details-body h3{@apply mb-5 mt-7 text-[27px] lg:text-[38px] xl:text-[48px] font-normal leading-tight lg:leading-[1.2] lg:tracking-[-1.68px]}
.blog-details-body p{@apply md:text-[22px] md:leading-[1.4] text-colorText dark:text-dark-100}
.blog-details-body ul{@apply my-5 ml-4 md:ml-6}.blog-details-body ul li{@apply list-disc md:text-[21px] md:leading-[1.4] text-colorText dark:text-dark-100}
.blog-details-body img{@apply mt-9 lg:mt-[60px]}.menu-list-item .menu-list-item-text{@apply transition duration-300 relative}
.menu-list-item .menu-list-item-text::before{@apply content-none lg:content-[""] absolute w-48 h-[1px] right-0 top-1/2 translate-y-1/2 translate-x-[120%] bg-white/10 transition duration-300 scale-0 origin-left}
.menu-list-item .menu-list-item-dropdown{@apply opacity-0 pointer-events-none transition duration-300 translate-x-3 h-0 md:h-fit}
.menu-list-item .menu-list-item-dropdown .menu-list-item-dropdown-list.active{@apply text-primary font-bold}
.menu-list-item .menu-list-item-dropdown .menu-list-item-dropdown-list.active span{@apply text-primary}
.menu-list-item.active .menu-list-item-text{@apply text-primary}.menu-list-item.active .menu-list-item-text::before{@apply scale-100}
.menu-list-item.active .menu-list-item-dropdown{@apply opacity-100 pointer-events-auto translate-x-0 h-fit}
.swiper-wrapper{@apply relative w-full h-full z-[1] flex;transition-property:transform;transition-timing-function:var(--swiper-wrapper-transition-timing-function,initial);box-sizing:content-box}
.swiper-android .swiper-slide,.swiper-ios .swiper-slide,.swiper-wrapper{transform:translate3d(0,0,0)}
.swiper-horizontal{@apply touch-pan-y}.swiper-slide{@apply flex-shrink-0 w-full h-full relative block;transition-property:transform}
.swiper-slide-invisible-blank{@apply invisible}.animate-marquee{animation:marqueeAni 14000ms linear infinite}
@keyframes marqueeAni{0%{transform:translateX(0)}100%{transform:translateX(-100%)}
}.reveal-me{@apply overflow-hidden}.pointer{@apply bg-backgroundBody rounded-full hidden lg:flex justify-center self-center items-center w-4 h-4 mix-blend-difference fixed pointer-events-none -translate-x-2 -translate-y-2 z-[9999]}
.service-item-wrapper{counter-reset:service-item-number}.service-item-wrapper .service-item-number::before{@apply absolute right-[50px] -translate-x-[50px] md:right-[40px] md:-translate-x-[40px] lg:right-[60px] lg:-translate-x-[60px];background:-webkit-linear-gradient(rgba(134,133,133,0.6),#fff);background-clip:text;-webkit-background-clip:text;@apply text-[180px];-webkit-text-fill-color:transparent;@apply top-0;@apply -z-10;counter-increment:service-item-number;content:"" counter(service-item-number);@apply font-black}
.dark .service-item-wrapper .service-item-number::before{@apply absolute right-[50px] -translate-x-[50px] md:right-[40px] md:-translate-x-[40px] lg:right-[60px] lg:-translate-x-[60px];background:-webkit-linear-gradient(rgba(134,133,133,0.6),rgba(21,21,21,0.6));background-clip:text;-webkit-background-clip:text;@apply text-[180px];-webkit-text-fill-color:transparent;@apply top-0;@apply -z-10;counter-increment:service-item-number;content:"" counter(service-item-number);@apply font-black}
.blog-title h3{@apply inline w-full cursor-pointer pb-[1px];@apply transition-all duration-500 ease-[cubic-bezier(0.645,0.045,0.355,1.000)];background-image:linear-gradient(transparent calc(100% - 2px), #101010 2px);@apply bg-[length:0_100%] bg-no-repeat}
.dark .blog-title h3{background-image:linear-gradient(transparent calc(100% - 2px), #ffffff 2px)}
.blog-title:hover h3{@apply bg-[length:100%_100%]}.underline-hover-effect:hover .blog-title h3{@apply inline w-full cursor-pointer pb-[1px];@apply transition-all duration-500 ease-[cubic-bezier(0.645,0.045,0.355,1.000)];background-image:linear-gradient(transparent calc(100% - 2px), #101010 2px);@apply bg-[length:0_100%] bg-no-repeat}
.dark .underline-hover-effect:hover .blog-title h3{background-image:linear-gradient(transparent calc(100% - 2px), #ffffff 2px)}
.underline-hover-effect:hover .blog-title h3{@apply bg-[length:100%_100%]}.team-title h3{@apply inline w-full cursor-pointer pb-[1px];@apply transition-all duration-700 ease-in-out;background-image:linear-gradient(transparent calc(100% - 2px), #ffffff 2px);@apply bg-[length:0_100%] bg-no-repeat}
.team-title:hover h3{@apply bg-[length:100%_100%]}.project-title h3{@apply inline w-full cursor-pointer pb-[1px];@apply transition-all duration-500 ease-[cubic-bezier(0.645,0.085,0.355,1.000)];background-image:linear-gradient(transparent calc(100% - 2px), #101010 2px);@apply bg-[length:0_100%] bg-no-repeat}
.project-title:hover h3{@apply bg-[length:100%_100%]}.underline-hover-effect:hover .project-title h3{@apply inline w-full cursor-pointer pb-[1px];@apply transition-all duration-500 ease-[cubic-bezier(0.645,0.045,0.355,1.000)];background-image:linear-gradient(transparent calc(100% - 2px), #101010 2px);@apply bg-[length:0_100%] bg-no-repeat}
.underline-hover-effect:hover .project-title h3{@apply bg-[length:100%_100%]}.interest-btn{@apply inline-block cursor-pointer border px-3 py-2 text-center text-base font-medium transition-all duration-300 ease-in-out hover:bg-gray-100 md:px-[42px] md:py-5 dark:border-dark dark:hover:bg-slate-900}
.checkbox-wrapper input[type=checkbox]:checked+.interest-btn{@apply bg-primary/80 text-secondary hover:bg-primary dark:hover:bg-primary}
.radio-wrapper input[type=radio]:checked+.interest-btn{@apply bg-primary/80 text-secondary hover:bg-primary dark:hover:bg-primary}
.service-card:hover .content{transform:translateY(0);transition:transform .5s ease-in-out}
.footer-text{background:-webkit-radial-gradient(#fff,#101010);-webkit-background-clip:text;-webkit-text-fill-color:transparent}
.swiper-slide{transition:filter .4s ease}.swiper-slide .rv-badge,.swiper-slide h5{transition:opacity .4s ease}
.swiper-slide-active{z-index:2}.toggle-slider{transition:transform .5s cubic-bezier(0.4,0,0.2,1)}
.toggle-option{transition:color .3s ease}.yearly .toggle-slider{transform:translateX(calc(100% - 2px))}
.toggle-option.active{color:black}.cta-slider-container{@apply relative h-24 md:h-[100px] w-60 md:w-[250px] overflow-hidden mx-auto inline-block rounded-[70px]}
.cta-slider-container .cta-inline-slider{@apply absolute w-full h-full transition-transform duration-[0.5s] ease-in-out}
.cta-slider-container .slide{width:100%;height:100px}.cta-slider-container .slide img{width:100%;height:100%}
.headroom .gradient-shadow,.headroom .nav-top{will-change:transform;transition:transform 400ms linear}
.headroom--pinned .gradient-shadow,.headroom--pinned .nav-top{transform:translateY(0)}
.headroom--unpinned .gradient-shadow,.headroom--unpinned .nav-top{transform:translateY(-100%)}