// Career  tab-------
.tab-button {
  @apply relative transition-all duration-[0.6s];

  &.active {
    @apply bg-primary;
  }
}

.tab-content {
  animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
