@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/Satoshi-Regular.woff") format("woff"),
    url("../fonts/Satoshi-Medium.woff") format("woff"),
    url("../fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/Satoshi-Regular.ttf") format("truetype"),
    url("../fonts/Satoshi-Light.woff") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

p {
  div {
    @apply text-inherit;
  }
}
